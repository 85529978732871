<template>
	<v-container
		fluid
		class="pt-10"
	>
	
		<div class="text-h5"><span class="font-weight-bold">대리점명</span> 을 <br/>입력해주세요</div>
		
		<v-text-field
			v-model="search_code"
			append-icon="search"
			@click:append="getAgency"
			outlined
			color="success2"
			label="대리점명을 입력하세요"
			class="mt-10 rounded-t-lg rounded-b-0"
			hide-details
			
		></v-text-field>
		
		<v-card
			elevation="1"
			tile
		>
		
			<v-list 
				three-line
				style="max-height: auto; overflow: auto;"
				class="pa-0"
			>
				<v-radio-group
					v-for="(item, index) in items"
					:key="index"
					v-model="select_item"
					class="pt-0 pb-0 mt-0 mb-0"
					hide-details
				>
					<v-list-item
						class="pt-0 pb-0 mt-0 mb-0"
						style="border-bottom: 1px solid #ddd;"
						tile
					>
						<v-list-item-avatar>
							<v-radio
								color="success2"
								:value="item.agentSeq"
								hide-details
								@click="selectItem(index)"
							></v-radio>
						</v-list-item-avatar>

						<v-list-item-content
							
							>
							<v-list-item-title v-html="item.agentName"></v-list-item-title>
							<v-list-item-subtitle v-html="item.businessNumber"></v-list-item-subtitle>
							<v-list-item-subtitle v-html="item.agentAddress"></v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
					
				</v-radio-group>
				<v-list-item
					v-if="items.length <= 0"
					class="justify-center"
				>
					검색된 결과가 없습니다
				</v-list-item>
			</v-list>
			
		</v-card>
		
	</v-container>
</template>
<script>

	export default{
		name: 'FindId'
		,created: function(){
			//this.$emit('setProgram', this.program, this.options)
		}
		,props: ['callBack']
		,data: function(){
			return{
				program: {
					title: '서비스 신청'
					,not_navigation: true
					
				}
				,search_code: ''
				,item: {}
				,items: []
				,select_item: ''
				,lists: [
					{
						agentSeq:1
						,agentName: '(주)립페이 제강'
						,businessNumber: '123-456-789'
						,agentAddress: '서울시 강남구 선릉로 121길 7'
						,view: false
					}
					,{
						agentSeq:2
						,agentName: '(주)립페이 수산'
						,businessNumber: '123-456-789'
						,agentAddress: '서울시 송파구'
						,view: false
					}
					,{
						agentSeq:3
						,agentName: '(주)립페이 철물점'
						,businessNumber: '123-456-789'
						,agentAddress: '서울시 강남구'
						,view: false
					}
				]
				,email: ''
				,phone: ''
			}
		}
		,methods: {
			go: function(type){
				if(type == 'id'){
					this.$router.push('/Help/FindId')
				}else{
					this.$router.push('/Help/FindPw')
				}
			}
			// 대리점 조회
			,getAgency: function(){
				this.$emit('axios', {
					request_type: 'get'
					,request_url: '/apply/agents'
					,request_params: {
						keyword: this.search_code
					}
					,authorize: true
					,error_message: true
					,callBack: 'getAgencyResult'
				})
			}
			,getAgencyResult: function(call){
				this.items = call.items.content
			}
			,result: function(call){
				
				if(call.result){
					this.$emit('modal', {title: ''})
				}else{
					//
				}
			}
			// 대리점 선택
			,selectItem: function(index){
				this.item = this.items[index]
				this.$emit('setAgency', this.item)
			}
		}
		,watch: {
			callBack: function(call){
				console.log('watch agency : ' + call.name)
				console.log(call)
				if(call.name == 'getAgencyResult'){
					this.getAgencyResult(call)
				}
			}
			,search_code: {
				handler: function(call){
					console.log(call)
/*									
					this.getAgency(call)

					this.items = []
					if(call == ''){
						return false
					}
					for(var i = 0; i < this.lists.length; i++){
						let item = this.lists[i]
						if(item.agentName.indexOf(call) != -1){
						
							this.items.push(item)
						}
					}
*/					
				}
			}
		}
	}
</script>